@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

.bar {
  list-style: none;
}
.bar > div {
  position: relative;
}
.bar > div:before {
  content: "\25CF";
  margin-right: 10px;
  font-size: 20px;
}
.bar > div:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-left: 2px solid black;
  margin-left: 5px;
  height: 100%;
}
.bar > div:first-of-type:after {
  top: 50%;
}
.bar > div:last-of-type:after {
  top: -50%;
}
